//@import "global.module";

.main {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.header {
		background: var(--header);
		height: var(--headerH);
		display: flex;
		justify-content: space-between;
		box-shadow: inset 0 -1px 0 0 rgb(255 255 255 / 10%);
		.logo {
			position: absolute;
			width: 100%;
			min-width: var(--app-min-width);
			background: var(--headerLogo);
			height: var(--headerH);
		}
	}
	.status_bar {
		background: rgba(0,0,0,.2);
		height: var(--statusBarH);
		color: #6f7183;
		padding: 4px;
		font-size: 0.65em;
		display: flex;
		justify-content: space-between;
	}
}
