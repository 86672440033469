@import "shared/themes/fonts";

.bet {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	row-gap: 1.88px;
	color: rgba(255, 255, 255, 1);
	@include font('Inter', 10px, 500, 12px);
}