$color-error: rgba(255, 0, 0, 0.7);
$color-warning: rgba(255, 127, 0, 0.7);
$color-approval: rgb(59, 164, 93, 0.7);

.errorBlock {
    position: fixed;
    top: 0;
    left: 1rem;
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    opacity: 0;
    transition: var(--transition);
    pointer-events: none;
    z-index: 50;

    > div {
        $border-size: 2px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;
        max-width: 500px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0.5rem;
        border: $border-size solid transparent;
        box-shadow: 0 0.5rem 0.75rem black;
        backdrop-filter: blur(0.5rem);
        pointer-events: none;
        overflow: hidden;

        %container {
            display: flex;
            flex-flow: column nowrap;
        }

        > div.wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: stretch;
            gap: 1rem;
            padding: 1rem;

            > span.text {
                @extend %container;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                color: rgba(255, 255, 255, 0.7);
                font-weight: bold;
                cursor: default;
            }

            > span.control {
                @extend %container;
                justify-content: flex-start;
                align-items: center;

                button {
                    $size: 1rem;
                    position: relative;
                    height: $size;
                    width: $size;
                    background: transparent;
                    margin: 0;
                    padding: 0;
                    outline: none;
                    border: none;
                    cursor: pointer;

                    svg {
                        height: $size;
                        width: $size;
                        fill: rgba(255, 255, 255, 0.7);
                        transform: rotate(0deg);
                        transition: 0.3s var(--transition-func);
                    }

                    &:hover {
                        svg {
                            fill: rgba(255, 255, 255, 0.9);
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        > div.progressbar {
            > .progress {
                $height: 0.2rem;
                height: $height;
                background: white;
                margin-top: -$height;
            }
        }

        &[data-type] {
            &[data-type="error"] {
                background: $color-error;
            }

            &[data-type="warning"] {
                background: $color-warning;
            }

            &[data-type="approval"] {
                background: $color-approval;
            }
        }

        &[data-important] {
            &[data-important="true"] {
                border: $border-size solid red;
            }
        }

        &:first-child {
            margin-top: 1rem;
        }
    }

    &.active {
        opacity: 1;

        > div {
            pointer-events: auto;
        }
    }

    @media screen and (max-width: 699px) {
        left: 0;
        align-items: center;
        > span {
            width: 80%;
        }
    }
}

.min {
    position: fixed;
    top: 0;
    right: 0;
    min-height: 2rem;
    min-width: 2rem;
    display: flex;
    flex-flow: column;
    row-gap: 0.2rem;
    justify-content: center;
    align-items: stretch;
    background: rgba(0,0,0,.7);
    padding: 0.25rem;
    backdrop-filter: blur(1rem);
    border-radius: 0 0 0 0.2rem;
    color: whitesmoke;
    font-size: 12px;
    transform: translateX(100%);
    transition: var(--transition);
    cursor: pointer;
    z-index: 100;

    &.active {
        transform: translateX(0);
    }

    > .list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        > .mark {
            $size: 10px;
            height: $size;
            width: $size;
            display: block;
            border-radius: 50%;
            animation: 1.75s pulse 0s ease-in-out infinite normal;

            &[data-variant="error"] {
                background: $color-error;
            }
            &[data-variant="warning"] {
                background: $color-warning;
            }
            &[data-variant="another"] {
                background: whitesmoke;
            }

            @keyframes pulse {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.7;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }
}
