@import "shared/themes/fonts";

%title {
	@include font('Inter', 10px, 500, 12px);
	letter-spacing: 0.02em;
	color: rgba(192, 235, 202, 1);
}
%value {
	@include font('Inter', 12px, 700, 14.52px);
	letter-spacing: 0.02em;
	color: rgba(192, 235, 202, 1);
}
%label {
	@include font('Inter', 12px, 700, 15px);
	letter-spacing: 0.02em;
	color: rgba(192, 235, 202, 1);
}

.info {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	row-gap: 20px;
	padding: 64px;

	.center {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 5px;
	}

	.shortInfo {
		$column-gap: 10px;
		display: flex;
		flex-direction: row-reverse;
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;
		row-gap: 13px;
		column-gap: $column-gap;

		> * {
			flex: 0 1 calc(50% - $column-gap);
			display: flex;
			flex-direction: column;
			align-items: stretch;
			text-align: center;
			row-gap: 10px;
		}
	}

	[data-show] {
		&[data-show="true"] { opacity: 1; }
		&[data-show="false"] { opacity: 0; }
	}
}