@import "shared/themes/fonts";

.basicRulesSheet {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	justify-content: flex-start;
	background: rgba(40, 47, 49, 1);
	padding: 24px;
	z-index: 20;
	overflow-y: auto;

	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		row-gap: 24px;

		.header {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: stretch;

			> .controls {
				.button {
					padding: 0;

					.icon {
						$size: 25px;
						height: $size;
						width: $size;
						fill: rgba(130, 130, 130, 1);
					}
				}
			}

			> .title {
				flex: 1 1;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				color: rgba(192, 235, 202, 1);
				@include font('Inter', 12px, 500, 15px);
			}
		}

		.basicRules {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
	}
}