@import "shared/themes/fonts";
@import "shared/ui/sheets/style.module";

.pokerTable {
	position: relative;
	width: 100%;
	color: #acacac;
	font-size: 0.797em;
}

.sheet {
	> .container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;
		padding: 0 38px;

		> .title {
			color: rgba(192, 235, 202, 1);
			text-align: center;
			@include font('Inter', 16px, 600, 19px);
		}

		> .line {
			height: 1px;
			width: 100%;
			background: rgba(116, 140, 124, 1);
		}

		> .controls {
			@extend %controls-wrapper;

			> .button {
				flex: 1 0;
			}
		}
	}
}
