@import "shared/themes/fonts";

.basicRules {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 1rem;
	color: rgba(172, 191, 177, 1);
	@include font('Inter', 12px, 500, 250%);
	letter-spacing: 0.02em;
}