@import "shared/themes/fonts";
@import "shared/ui/sheets/style.module";

.lobbyFilter {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: stretch;

	> .header { @extend %header; }

	> .wrapper { @extend %chapter-wrapper; }

	.hr { @extend %hr; }

	.filters {
		display: flex;
		flex-direction: column;

		.filter {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			row-gap: 10px;
			text-align: center;

			> .title {
				@include font('Inter', 10px, 500, 12px);
				color: rgba(192, 235, 202, 1);
			}

			> .selector {
				.group {
					width: 100%;
				}
			}
		}

		.row {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			column-gap: 15px;

			> * {
				flex: 1 1;
			}
		}
	}

	.range {
		@extend %range-container;

		> .titles { @extend %range-titles; }
	}
}