@import "shared/themes/fonts";
@import "features/buyIn/sheet/style.module";

.lobbyPage {
	width: 100%;
	height: 100vh;
	min-height: -webkit-fill-available;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: stretch;
	overflow: hidden;

	> .headerContainer {
		min-height: 80px;
		z-index: 1;

		> .header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: stretch;

			> .controls {
				min-width: 2rem;
				display: flex;
				flex-direction: row;
				align-items: center;

				.icon {
					$size: 24px;
					height: $size;
					width: $size;
				}
			}

			> .title {
				flex: 1 1 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-around;
				align-items: center;

				> .text {
					@include font('Inter', 12px, 500, 15px);
					letter-spacing: 0.02em;
					color: rgba(192, 235, 202, 1);

					.radio {
						min-width: 150px;
						text-align: center;
					}
				}
			}
		}
	}

	> .tables {
		flex: 1 1 100%;
		overflow: hidden;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	> .board {
		flex: 1 1;
		justify-content: flex-start;
		padding: 0 24px;
		overflow-y: auto;
	}

	> .controlsContainer {
		min-height: 150px;
		z-index: 1;

		.controls {
			column-gap: 15px;

			> .button {
				flex: 1 0;

				svg {
					fill: rgba(130, 130, 130, 1);
				}
			}
		}
	}
}

.sheet {
	@extend .sheet;
}

.menu {
	$margin: 14px;
	position: fixed;
	top: $margin;
	left: $margin;
	max-height: calc(100% - 2 * $margin);
	max-width: calc(100% - 2 * $margin);
}