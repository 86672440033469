.authPage {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.tabForm {
		width: 280px;
		background: rgba(0,0,0,0.2);
		border-radius: 16px;
		color: #7f7e77;
		font-family: Inter;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		padding: 6px;
		.tabs {
			width: 100%;
			display: flex;
			overflow: hidden;
			justify-content: space-between;
			gap: 6px;
			.tab {
				width: 100%;
				padding: 6px;
				border-radius: 12px 12px 0 0;
				background: rgba(0,0,0,0.2);
				display: flex;
				justify-content: center;
				&.active {
					background: rgba(0,0,0,0.4);
					color: #bbb586;
					pointer-events: none;
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
		.content {
			border-radius: 0 0 12px 12px;
			background: rgba(0,0,0,0.4);
			padding: 6px;
		}
	}


	form {
		display: flex;
		flex-direction: column;
		gap: 3px;
		padding: 20px;
		color: burlywood;
		button {
			width: 100px;
			height: 24px;
			margin-top: 20px;
			background: #b3b3b3;
			border: 0;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
