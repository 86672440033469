.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&.paddings {
		padding: 20px;
	}

	> .content {
		max-width: 700px;
		width: 100%;
	}
}