.loader {
	position: relative;
	min-height: 100%;
	min-width: 100%;
	background: transparent url("../../assets/loading_preview.png") no-repeat scroll top center/cover;

	> .logo {
		position: absolute;
		top: 5%;
		left: 50%;
		width: 60%;
		max-width: 350px;
		transform: translateX(-50%);
	}

	@keyframes spinner {
		from {
			filter: drop-shadow(0 0 0.5rem rgba(0,0,0,0.3));
			transform: translateX(-50%) translateY(0) rotate(0deg);
		}
		50% {
			filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.7));
			transform: translateX(-50%) translateY(50%);
		}
		to {
			filter: drop-shadow(0 0 0.5rem rgba(0,0,0,0.3));
			transform: translateX(-50%) translateY(0) rotate(720deg);
		}
	}

	.chip {
		position: absolute;
		top: 30%;
		left: 50%;
		border-radius: 50%;
		animation: spinner 1.5s normal infinite ease-in-out;
	}
}