@import "shared/ui/sheets/style.module";

.raise {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: stretch;

	> .header { @extend %header; }

	> .wrapper { @extend %chapter-wrapper; }

	.hr { @extend %hr; }

	.range {
		@extend %range-container;

		> .titles { @extend %range-titles; }
	}

	.buttons {
		@extend %buttons-list;

		.button {
			@extend %button;
		}
	}
}