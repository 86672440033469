@import "shared/ui/menuPopup/style.module";

.container {
	@extend %container;

	.table {
		@extend %table;

		.room {
			background: rgba(22, 27, 28, 1);
			padding-top: 6px;
			padding-bottom: 6px;
			border-radius: 18px;

			.id {
				letter-spacing: 0.02em;
			}
		}
	}

	.listItem {
		@extend %list-item;
	}

	.navbar {
		.table {
			row-gap: 30px;
		}
	}
}