.range {
	$height: 50px;
	$border: 3px;
	$track-background: rgba(22, 27, 28, 1);
	$progress-background: rgba(192, 235, 202, 1);
	$thumb-background: rgba(22, 27, 28, 1);

	--points: 0;
	--part: 0;
	$thumb-size: $height - 2 * $border;
	$parts: calc(var(--points) - 1);
	position: relative;
	height: $height;
	width: 100%;
	background: $track-background;
	border-radius: $height * 0.5;

	> .progress {
		height: 100%;
		width: calc(((100% - ($thumb-size + 2 * $border)) / $parts) * var(--part) + $thumb-size + 2 * $border);
		background: $progress-background;
		border-radius: $height * 0.5;
	}

	> .track {
		position: absolute;
		top: $border;
		left: $border + 0.5 * $thumb-size;
		height: $thumb-size;
		width: calc(100% - 2 * $border - $thumb-size);

		> .thumb {
			position: absolute;
			top: 50%;
			left: calc((100% / $parts) * var(--part));
			height: $thumb-size;
			width: $thumb-size;
			background: $thumb-background;
			border-radius: $thumb-size * 0.5;
			transform: translateX(-50%) translateY(-50%);
			cursor: pointer;
		}
	}
}