@import "shared/themes/fonts";

.action {
	min-width: 50px;
	background: rgba(255, 255, 255, 1);
	padding: 1px 2px;
	border-radius: 39px;
	text-align: center;
	color: rgba(0, 0, 0, 1);
	@include font('Inter', 13.5533px, 500, 16px);

	&[data-action="fold"] {
		background: rgba(22, 27, 28, 1);
		color: rgba(245, 80, 100, 1);
	}

	&[data-action="check"], &[data-action="call"] {
		background: rgba(22, 27, 28, 1);
		color: rgba(192, 235, 202, 1);
	}

	&[data-action="raise"], &[data-action="all-in"] {
		background: rgba(22, 27, 28, 1);
		color: rgba(153, 233, 100, 1);
	}
}