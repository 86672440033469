.tablesWrapper {
	$top: 150px;
	$bottom: 150px;

	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: -$top;
	margin-bottom: -$bottom;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	> .icon {
		$size: 24px;
		$padding: 10px;
		position: absolute;
		top: 50%;
		height: $size;
		width: $size;
		translate: 0 -50%;
		opacity: 0;
		transition: 0.5s linear;
		visibility: hidden;
		z-index: 1;

		&.left {
			left: $padding;
			transform: scaleX(-1);
		}

		&.right {
			right: $padding;
		}

		&[data-visible="true"] {
			opacity: 1;
			visibility: visible;
		}

		&[data-disabled="true"] {
			opacity: 0.3;
			pointer-events: none;
		}
	}

	.carousel {
		$gap: 32px;
		flex: 1 1;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding-top: $top;
		padding-bottom: $bottom;
		column-gap: $gap;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;
		pointer-events: none;
		will-change: scroll-position;

		&::-webkit-scrollbar {
			display: none;
		}

		&.scroll {
			pointer-events: auto;
			scroll-snap-type: both mandatory;

			> * {
				scroll-snap-align: center;
			}
		}

		> * {
			flex: 0 0 100%;
		}
	}
}
