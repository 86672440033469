@import "shared/themes/fonts";

.Profile {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	background: rgba(40, 47, 49, 1);

	> .avatar {
		position: relative;
		min-height: 400px;
		width: 100%;
		background: rgba(217, 217, 217, 0.05);

		.images {
			height: 100%;
			max-height: 400px;
			width: 100%;
			object-position: center;
			object-fit: cover;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: stretch;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.addButton {
			$size: 80px;
			position: absolute;
			bottom: 0;
			right: 36px;
			height: $size;
			width: $size;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: rgba(53, 62, 64, 1);
			border: none;
			outline: none;
			border-radius: 50%;
			box-shadow:
					15px 15px 30px rgba(0, 0, 0, 0.25),
					inset -1px -1px 1px rgba(22, 29, 64, 0.15),
					inset 1px 1px 1px rgba(155, 163, 211, 0.1);
			transform: translateY(50%);
			cursor: pointer;

			> input {
				display: none;
			}

			> .icon {
				$size: 26px;
				height: $size;
				width: $size;
			}
		}
	}

	> .info {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
		row-gap: 25px;
		background: rgba(40, 47, 49, 1);
		padding: 35px 24px;

		%text {
			color: rgba(192, 235, 202, 1);
			@include font('Inter', 18px, 500, 22px);
		}

		.input {
			width: 100%;
			background: transparent;
			padding: 0;
			border: none;
			outline: none;
			@extend %text;
		}

		.nickname {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: baseline;

			> .symbol {
				@extend %text;
			}
		}

		.controls {
			gap: 20px;

			> * {
				flex: 1 1;
			}
		}
	}
}

.field {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 10px;

	> .placeholder {
		color: rgba(116, 140, 124, 1);
		@include font('Inter', 12px, 500, 15px);
	}

	> .wrapper {

	}
}