.page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 2rem;
	padding: 1.5rem;
	overflow-y: auto;

	.table {
		flex: 1 1;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: stretch;
		gap: 1rem;
		color: whitesmoke;

		.tr {
			flex: 1 1 20%;
			display: grid;
			grid-template-columns: 50px 50px auto;
			grid-template-rows: 1fr;

			> * {
				display: flex;

				&:nth-child(1) {
					flex-flow: row;
					justify-content: center;
					align-items: center;
				}

				&:nth-child(2) {
					flex-flow: row;
					justify-content: center;
					align-items: center;
				}

				&:nth-child(3) {
					flex-flow: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}
		}

		.card {
			max-height: 20px;
		}
	}
}
