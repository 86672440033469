.page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 2rem;
	padding: 1.5rem;
	overflow-y: auto;

	.table {
		flex: 1 1;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: stretch;
		gap: 1rem;
		color: whitesmoke;

		.iconContainer {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: stretch;
			row-gap: 1rem;

			.icon {
				display: flex;
				flex-flow: row nowrap;
				justify-content: center;
				align-items: center;
			}

			.name {
				text-align: center;
			}
		}
	}
}
