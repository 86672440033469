.tableWrapper {
	--ratio: 1;
	--player-size: 1;
	--border-radius: 0;
	$padding: var(--player-size);
	position: relative;
	transition: 0s;

	> .table {
		position: absolute;
		top: 50%;
		left: 50%;
		height: calc(100% - $padding);
		width: calc(100% - $padding * var(--ratio));
		border-radius: var(--border-radius, 0);
		box-shadow:
				0 0 75.84767150878906px 0 rgba(106, 125, 128, 0),
				16px 16px 32px rgba(0, 0, 0, 0.3),
				inset -45.1695px -45.1695px 75.848px rgba(0, 0, 0, 0.2); //inset -45.1695px -45.1695px 225.848px rgba(0, 0, 0, 0.2);
		transform: translateX(-50%) translateY(-50%);
		transition: var(--transition);

		> .svg {
			position: relative;
			height: 100%;
			width: 100%;
			background-color: transparent;
			background-repeat: no-repeat;
			background-attachment: scroll;
			background-position: center;
			background-size: contain;
		}
	}

	> .content {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 100%;
		width: 100%;
		transform: translateX(-50%) translateY(-50%);
	}

	&.highlight {
		> .table {
			box-shadow:
					0 0 75.84767150878906px 0 rgba(106, 125, 128, 0.75),
					16px 32px 8px rgba(0, 0, 0, 0.30),
					inset -45.1695px -45.1695px 75.848px rgba(0, 0, 0, 0.2);
		}
	}
}