@import "../../shared/themes/fonts";

@mixin createStyle($icon-color, $background-color: null) {
	@if ($background-color != null) {
		background: $background-color;
	}

	.icon {
		fill: $icon-color;
	}
}
@mixin createButtonStyle($name, $icon-color, $background-color: null) {
	&[data-key=#{$name}] {
		@include createStyle($icon-color, $background-color);
	}
}

.gameButtons {
	--buttons-count: 0;

	$color_0: rgba(71, 78, 79, 1); // default
	$color_1: rgba(116, 140, 124, 1); // text
	$color_2: rgba(192, 235, 202, 1); // text
	$color_3: rgba(255, 255, 255, 1); // button
	$color_4: rgba(245, 80, 100, 1); // button
	$color_5: rgba(189, 189, 189, 1); // button
	$color_6: rgba(153, 233, 100, 1); // button
	$color_7: rgba(153, 204, 165, 1); // button
	$color_8: rgba(112, 212, 44, 1); // button
	$transition: var(--transition);

	flex: 1 0;
	display: grid;
	grid-template-columns: repeat(var(--buttons-count), 1fr);
	grid-template-rows: auto;
	row-gap: 10px;
	column-gap: 8px;

	@media screen and (min-width: 350px) {
		column-gap: 14px;
	}

	@media screen and (min-width: 375px) {
		column-gap: 20px;
	}

	> .wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.gameButton {
			flex-direction: column;
			background: rgba(53, 62, 64, 1);
			box-shadow: 15px 15px 30px 0 rgba(0, 0, 0, 0.25),
				1px 1px 1px 0 #555859 inset,
				-1px -1px 1px 0px rgba(11, 11, 13, 0.25) inset;
			transition: $transition;
			@include createStyle($color_0);

			.icon {
				fill: rgba(71, 78, 79, 1);
				transition: $transition;
			}

			&.secondary {
				.icon {
					$size: 22px;
					height: $size;
					width: $size;
				}
			}

			&.tertiary {
				.icon {
					$size: 18px;
					height: $size;
					width: $size;
				}
			}

			&.active {
			}

			&.await {
			}
		}
	}

	.text {
		color: $color_0;
		text-align: center;
		@include font('Inter', 12px, 500, 15px);
		text-shadow: 0 1.1px 1.1px rgba(0, 0, 0, 0.15);

		&.active {
			color: $color_0;
		}
	}

	&[data-variant="disabled"] {
		pointer-events: none;

		> .wrapper {
			.gameButton {
				@include createStyle($color_0);
			}
		}

		.text {
			color: $color_0;

			&.active {
				color: $color_0;
			}
		}
	}

	&[data-variant="await"], &[data-variant="active"] {
		> .wrapper {
			.gameButton {
				&.active {
					@include createButtonStyle("fold", $color_4);
					@include createButtonStyle("raise", $color_6);
				}

				&.await {
					@include createButtonStyle("fold", $color_3, $color_4);
					@include createButtonStyle("raise", $color_3, $color_8);
				}
			}
		}
	}

	&[data-variant="await"] {
		> .wrapper {
			.gameButton {
				&.active {
					@include createButtonStyle("check", $color_5);
					@include createButtonStyle("call", $color_6);
				}

				&.await {
					@include createButtonStyle("check", $color_3, $color_7);
					@include createButtonStyle("call", $color_3, $color_8);
				}
			}
		}

		.text {
			color: $color_1;
		}
	}

	&[data-variant="active"] {
		> .wrapper {
			.gameButton {
				&.active {
					@include createButtonStyle("check", $color_5);
					@include createButtonStyle("call", $color_5);
				}

				&.await {
					@include createButtonStyle("check", $color_3, $color_7);
					@include createButtonStyle("call", $color_3, $color_8);
				}
			}
		}

		.text {
			color: $color_2;
		}
	}
}