@import "shared/themes/fonts";

.rankings {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	row-gap: 25px;
}

.rank {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	column-gap: 24px;

	> .cards {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
		column-gap: 6px;

		> .card {
			max-height: 48px;
			max-width: 34px;

			&.inactive {
				opacity: 0.5;
			}

			.rank {
				font-weight: 600;
				font-size: 18px;
				line-height: 15px;
			}

			.suit {
				$size: 16px;
				height: $size;
				width: $size;
			}
		}
	}

	> .name {
		color: rgba(192, 235, 202, 1);
		@include font('Inter', 12px, 500, 15px);
	}
}