@import "shared/ui/sheets/style.module";

.sheet {
	> .container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;

		.controls {
			@extend %controls-wrapper;

			> .button {
				flex: 1 0;
			}
		}
	}
}