@import "shared/themes/fonts";

.radioGroup {
	$border-radius: 24px;
	position: relative;
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: stretch;
	background: rgba(22, 27, 28, 1);
	border-radius: $border-radius;

	&.disabled {
		pointer-events: none;
	}

	> .container {
		flex-grow: 1;
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		z-index: 1;

		> .item {
			flex-grow: 1;
			flex-shrink: 0;
			min-width: $border-radius * 2;
			padding: 10px;
			border-radius: $border-radius;
			color: rgba(192, 235, 202, 1);
			text-align: center;
			@include font('Inter', 12px, 400);
			transition: var(--transition);
			cursor: pointer;

			&.active {
				color: rgba(39, 46, 48, 1);
				@include font('Inter', 12px, 700);
				cursor: default;
			}
		}
	}

	> .substrate {
		position: absolute;
		background: rgba(192, 235, 202, 1);
		border-radius: $border-radius;
		z-index: 0;
		transition: var(--transition);
		cursor: default;
		user-select: none;
	}
}