.placeholder {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	> .icon {
		$size: 160px;
		height: $size;
		width: $size;
		fill: rgba(39, 46, 48, 1);
	}
}