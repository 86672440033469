.controlsList {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;

	&.column {
		flex-direction: column;
	}

	&.wrap {
		flex-wrap: wrap;
	}
}