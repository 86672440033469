.bank {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: rgba(22, 27, 28, 1);
	padding: 2px;
	border-radius: 27px;

	> .chip {
		$size: 16px;
		height: $size;
		width: $size;
	}

	> .value {
		padding: 0 10px;
		color: rgba(255, 255, 255, 1);
		font-family: 'Inter', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
	}
}