.dev {
	&.button {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		background: rgba(22, 27, 28, 1);
		border-radius: 0.25rem;
		border: none;
		outline: none;
		color: #acacac;
		box-shadow: 0 0 .5rem 0.1rem black;
		z-index: 1;
	}
	&.table {
		position: absolute;
		top: 0;
		left: 0;
		height: auto;
		width: 100%;
		background: rgba(0, 53, 85, 0.9);
		border: 1px #acacac solid;
		padding: 6px;
		cursor: pointer;
		font-size: 0.797em;
		z-index: 2;
	}
	&.el {
		background: rgba(0, 53, 85, 0.9);
		border: 1px #acacac solid;
	}
}