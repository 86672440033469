@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
	--app-min-width: 320px;
	--app-height: 100%;
	--baseBg: rgba(39, 46, 48, 1);
	--secondBg: #003555;
	--header: rgb(1,49,93);
	--headerH: 40px;
	--statusBarH: 20px;
	--headerLogo: url("../../../public/images/logo.svg") no-repeat center/auto 60%;
	--transition-time: 0.15s;
	--transition: var(--transition-time) linear;
}

* {
	box-sizing: border-box;
	user-select: none;
}

a, abbr, acronym, address, applet, b, big, blockquote, caption, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, i, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, u, ul, var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: top;
	background: 0 0;
	list-style-type: none;
	text-decoration: none;
}

input {
	user-select: text;
}

body {
	height: var(--app-height, 100vh);
	min-height: -webkit-fill-available;
	min-width: var(--app-min-width);
	margin: 0;
	padding: 0;
	background: var(--baseBg);
	font-family: 'PT Sans',Arial,Helvetica,sans-serif;

	> :global(#app-root) {
		width: 100%;
		height: 100%;
		min-height: -webkit-fill-available;
		overflow: hidden;
	}
}
