@import "shared/themes/fonts";

.Card {
	--color: rgba(35, 35, 38, 1);
	$color: var(--color, rgba(35, 35, 38, 1));

	min-height: 49px;
	min-width: 35px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	padding: 6px;
	border-radius: 6px;
	aspect-ratio: 35 / 49;

	> .rank {
		align-self: flex-start;
		color: $color;
		@include font('Gilroy', 19.3595px, 600, 16px);
	}

	> .suit {
		$size: var(--suit-size, 17px);
		align-self: flex-end;
		height: $size;
		width: $size;
	}

	&[data-variant] {
		&[data-variant="card"] {
			background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(218, 244, 224, 1) 100%);
		}

		&[data-variant="placeholder"] {
			background: rgba(53, 62, 64, 0.15);
		}
	}

	&[data-hidden="true"] {
		visibility: hidden;
	}
}

.CardBack {
	min-height: 32px;
	min-width: 28px;
	aspect-ratio: 28 / 32;

	svg {
		height: 100%;
		width: 100%;
		display: block;
	}
}