@mixin font($family: 'Inter', $size: null, $weight: null, $line-height: null) {
	font-family: $family, sans-serif;
	@if ($size != null and $size != 0) {
		font-size: $size;
	}
	@if ($weight != null and $weight != 0) {
		font-weight: $weight;
	}
	@if ($line-height != null and $line-height != 0) {
		line-height: $line-height;
	}
}