@import "shared/themes/fonts";

.tables_board {
	> .tables_list {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: stretch;
		overflow-y: auto;
	}

	.table {
		$border-radius: 8px;
		display: grid;
		grid-template-columns: repeat(5, minmax(auto, 1.33fr));
		grid-template-rows: 1fr;
		padding: 13px 10px;
		border-radius: $border-radius;
		text-align: center;
		color: rgba(192, 235, 202, 1);
		@include font('Inter', 12px, 500, 15px);
		transition: var(--transition);
		cursor: pointer;

		&:nth-child(2n) {
			background: rgba(116, 140, 124, 0.1);
		}

		&.active {
			background: rgba(153, 233, 100, 1);
			color: rgba(40, 47, 49, 1);
			cursor: default;
		}

		&.header {
			position: sticky;
			top: 0;
			background: var(--baseBg);
			border-radius: 0 0 $border-radius $border-radius;
			color: rgba(116, 140, 124, 1);
			cursor: default;
		}
	}
}