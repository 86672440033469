@import "shared/themes/fonts";

.messages {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	row-gap: 18px;
	column-gap: 20px;

	> .message {
		background: rgba(22, 27, 28, 1);
		padding: 12.5px 32px;
		border-radius: 30px;
		color: rgba(192, 235, 202, 1);
		@include font('Inter', 12px, 500, 15px);
	}
}