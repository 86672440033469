.container {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	> .table {
		--scale: 1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(var(--scale));
	}

	> .wrapper {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}
}