.slide {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	> * {
		flex: 1 1;
		height: 100%;
		width: 100%;
	}
}