@import "shared/themes/fonts";

.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	border: none;
	outline: none;
	cursor: pointer;

	&.primary {
		background: rgba(53, 62, 64, 1);
		padding: 16px 12px;
		border-radius: 18px;
		box-sizing: 15px 15px 30px rgba(0, 0, 0, 0.25),
			inset -1px -1px 1px rgba(11, 11, 13, 0.25),
			inset 1px 1px 1px #555859;
		@include font('Inter', 12px, 500, 12px);
		color: rgba(192, 235, 202, 1);
		box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.25),
			inset -1px -1px 1px rgba(22, 29, 64, 0.25),
			inset 1px 1px 1px rgba(155, 163, 211, 0.15);

		.icon {
			$size: 20px;
			height: $size;
			width: $size;
		}

		&:active {
			background: rgba(42, 49, 51, 1);
			box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25),
				inset -1px -1px 1px rgba(11, 11, 13, 0.25),
				inset 1px 1px 1px rgba(155, 163, 211, 0.15);
		}
	}

	&.secondary {
		background: rgba(53, 62, 64, 1);
		padding: 17.5px;
		border-radius: 18px;
		box-shadow: 16px 16px 32px rgba(0, 0, 0, 0.25),
			inset -1px -1px 1px rgba(11, 11, 13, 0.25),
			inset 1px 1px 1px #555859;

		.icon {
			$size: 20px;
			height: $size;
			width: $size;
		}
	}

	&.tertiary {
		background: rgba(53, 62, 64, 1);
		padding: 17px;
		border-radius: 18px;
		box-shadow: 16px 16px 32px rgba(0, 0, 0, 0.25),
			inset -1px -1px 1px rgba(11, 11, 13, 0.25),
			inset 1px 1px 1px #555859;

		.icon {
			$size: 16px;
			height: $size;
			width: $size;
		}
	}

	&.main {
		$primary-color: rgba(22, 27, 28, 1);
		$secondary-color: rgba(116, 140, 124, 1);
		$min-size: 36px;
		min-height: $min-size;
		min-width: $min-size;
		background: $primary-color;
		border-radius: 18px;
		padding: 4px;

		.icon {
			$size: 24px;
			height: $size;
			width: $size;
			fill: $secondary-color;
		}

		&.active {
			background: $secondary-color;

			.icon {
				fill: $primary-color;
			}
		}
	}

	&.empty {
		background: transparent;
		padding: 4px;

		.icon {
			$size: 20px;
			height: $size;
			width: $size;
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}