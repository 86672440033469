.users {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	%column {
		height: 100%;
		flex-direction: column;
	}
	%column-revers {
		flex-direction: column-reverse;
	}
	%row {
		width: 100%;
		flex-direction: row;
	}
	%row-revers {
		flex-direction: row-reverse;
	}

	> .side {
		$player-size: 52px;
		position: absolute;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-around;
		align-items: center;

		&[data-side="0"] {
			top: 0;
			left: 0;
			@extend %column;
			@extend %column-revers;
		}
		&[data-side="1"] {
			top: 0;
			left: 0;
			@extend %row;
		}
		&[data-side="2"] {
			bottom: 0;
			right: 0;
			@extend %column;
		}
		&[data-side="3"] {
			bottom: 0;
			right: 0;
			@extend %row;
			@extend %row-revers;
		}
		&[data-side="1"], &[data-side="3"] {
			padding: 0 0.5*$player-size;
		}
		&[data-side="0"], &[data-side="2"] {
			padding: $player-size 0;
		}
	}
}