@import "shared/themes/fonts";

.sheet {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	z-index: 1;
	pointer-events: none;

	&.bottom {
		justify-content: flex-end;
	}

	> .background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0,0,0,.3);
		z-index: 0;
		cursor: default;
		pointer-events: none;

		&.interactive {
			cursor: pointer;
			pointer-events: auto;
		}
	}

	> .container {
		max-height: 100%;
		background: rgba(40, 47, 49, 1);
		padding: 0 20px;
		overflow: auto;
		pointer-events: auto;
		z-index: 1;
	}
}

%header {
	text-align: center;
	color: rgba(192, 235, 202, 1);
	@include font('Inter', 16px, 600, 19px);
	letter-spacing: 0.02em;

	&.withPadding {
		padding: 18px 0;
	}
}

%hr {
	height: 1px;
	width: 100%;
	background: rgba(116, 140, 124, 1);
	opacity: 0.25;
}

%chapter-wrapper {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 32px;
}

%range-container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 10px;
	text-align: center;
}

%range-titles {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;

	> .title {
		@include font('Inter', 12px, 500, 15px);
		color: rgba(192, 235, 202, 1);
	}

	> .min, > .max {
		color: rgba(116, 140, 124, 1);
		@include font('Inter', 10px, 500, 12px);
		text-align: center;
		opacity: 0.5;
	}
}

%buttons-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	gap: 15px;
}

%button {
	background: rgba(22, 27, 28, 1);
	padding: 8px 30px;
	border-radius: 30px;
	color: rgba(192, 235, 202, 1);
	text-align: center;
	cursor: pointer;

	&.inactive {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}
}

%controls-wrapper {
	column-gap: 15px;
	padding: 42px 0 50px;
}