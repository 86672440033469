@import "shared/themes/fonts";
@import "shared/ui/sheets/style.module";

.buyIn {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: stretch;

	> .header { @extend %header; }

	> .wrapper { @extend %chapter-wrapper; }

	.hr { @extend %hr; }

	.range {
		@extend %range-container;

		> .titles { @extend %range-titles; }
	}

	.buttons {
		@extend %buttons-list;

		.button { @extend %button; }
	}

	.balance {
		text-align: center;
		color: rgba(192, 235, 202, 1);
		@include font('Inter', 12px, 500, 15px);
	}
}