@import "shared/themes/fonts";

.container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	background: rgba(0,0,0,.3);
	padding: 1rem;
	backdrop-filter: blur(0.5rem);
	z-index: 100;

	> .message {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		background: var(--baseBg);
		padding: 1rem;
		border-radius: 0.5rem;
		color: rgba(192, 235, 202, 1);
		@include font('Inter', 0.75rem, 500, 1rem);
		box-shadow: 0 0 25.848px rgba(106, 125, 128, 0.75);
	}
}