@import "shared/themes/fonts";
@import "shared/ui/sheets/style.module";

.gamePage {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: -webkit-fill-available;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

	> .headerContainer {
		min-height: 80px;
		z-index: 1;
		pointer-events: none;

		.header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: stretch;
			pointer-events: none;

			> .controls {
				min-width: 2rem;
				display: flex;
				flex-direction: row;
				align-items: center;
				pointer-events: auto;
			}

			> .title {
				flex: 1 1 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-around;
				align-items: center;

				> .text {
					color: rgba(192, 235, 202, 1);
					@include font('Inter', 12px, 500, 15px);
					letter-spacing: 0.02em;

					.number {
						font-weight: 700;
						font-size: 12px;
						line-height: 14.52px;
					}
				}

				> .icon {
					$size: 24px;
					height: $size;
					width: $size;

					&.left {
						transform: scaleX(-1);
					}

					&.right {

					}
				}
			}
		}
	}

	> .content {
		flex: 1 1 100%;
		padding: 0 50px;
	}

	> .controlsContainer {
		margin-top: auto;
		min-height: 150px;

		.controls {
			justify-content: space-between;
			align-items: center;
			gap: 20px;
		}
	}
}

.sheet {
	> .container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;
		padding: 0 38px;

		> .title {
			color: rgba(192, 235, 202, 1);
			text-align: center;
			@include font('Inter', 16px, 600, 19px);
		}

		> .line {
			height: 1px;
			width: 100%;
			background: rgba(116, 140, 124, 1);
		}

		> .controls {
			@extend %controls-wrapper;

			> .button {
				flex: 1 0;
			}
		}
	}

	&.messages {
		> .container {
			padding: 0;

			.contentContainer {
				justify-content: flex-start;
				padding: 24px;

				.contentWrapper {
					display: flex;
					flex-flow: column;
					justify-content: flex-start;
					align-items: stretch;
					row-gap: 24px;

					.headControls {
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						column-gap: 5px;
					}
				}
			}
		}
	}

	&.history {
		> .container {
			height: 100vh;
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			align-items: stretch;
			padding: 0;
			overflow-y: auto;

			.contentContainer {
				justify-content: flex-start;
				padding: 20px;

				.contentWrapper {
					display: flex;
					flex-flow: column;
					justify-content: flex-start;
					align-items: stretch;
					row-gap: 24px;

					.header {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: space-between;
						align-items: stretch;
						column-gap: 5px;

						> .controls {
							min-width: 2rem;
							display: flex;
							flex-flow: row nowrap;
							align-items: center;

							.button {
								padding: 0;

								.icon {
									$size: 25px;
									height: $size;
									width: $size;
									fill: rgba(130, 130, 130, 1);
								}
							}

							&:first-child {
								justify-content: flex-start;
							}

							&:last-child {
								justify-content: flex-end;
							}
						}

						> .title {
							flex: 1 1;
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							color: rgba(192, 235, 202, 1);
							@include font('Inter', 12px, 500, 15px);
						}
					}

					.content {
						display: flex;
						flex-flow: column;
						justify-content: flex-start;
						align-items: center;
					}
				}
			}
		}
	}
}

.menu {
	$margin: 14px;
	position: fixed;
	top: $margin;
	left: $margin;
	max-height: calc(100% - 2 * $margin);
	max-width: calc(100% - 2 * $margin);
}