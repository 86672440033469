@import "shared/themes/fonts";

$primaryColor: rgba(192, 235, 202, 1);
$secondaryColor: rgba(79, 79, 79, 1);
$tertiaryColor: rgba(116, 140, 124, 1);

.menu {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	row-gap: 10px;
	background: rgba(40, 47, 49, 1);
	padding: 24px 12px;
	border-radius: 20px;
	box-shadow: 0 0 225px rgba(106, 125, 128, 0.75),
		12px 24px 32px rgba(0, 0, 0, 0.25);
	z-index: 10;

	> .headControls {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		column-gap: 5px;

		.button {
			padding: 0;
		}
	}

	> .container {
		@extend %container;

		> .profile {
			display: flex;
			flex-direction: row;
			align-items: center;
			column-gap: 10px;

			.avatar {
				position: relative;
				$size: 56px;
				height: $size;
				width: $size;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: $primaryColor;
				box-shadow: inset -1px -1px 1px rgba(22, 29, 64, 0.15), inset 1px 1px 1px rgba(155, 163, 211, 0.1);
				overflow: hidden;
				cursor: pointer;

				> span {
					font-weight: 900;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				row-gap: 6px;

				.name {
					color: $primaryColor;
					@include font('Inter', 12px, 500, 15px);
				}

				.balance {
					color: $tertiaryColor;
					@include font('Inter', 10px, 500, 12px);
					letter-spacing: 0.02em;
				}
			}
		}
	}
}

%container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 24px;
}

%table {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 9px;
}

%list {
	> .table {
		@extend %table;
		row-gap: 30px;
	}
}

%list-item {
	$transition: var(--transition);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0 20px;
	color: $primaryColor;
	@include font('Inter', 12px, 500, 15px);
	transition: $transition;

	.icon {
		$size: 22px;
		height: $size;
		width: $size;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		fill: $primaryColor;
		margin-right: 17px;
		transition: $transition;
	}

	&.clickable {
		cursor: pointer;
	}

	&.inactive {
		color: $secondaryColor;
		cursor: default;
		pointer-events: none;

		.icon {
			fill: $secondaryColor;
		}
	}
}