.preloader {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	user-select: none;

	.preloader_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.search_preloader_wrap {
			.search_preloader {
				width: 64px;
				height: 64px;
				margin: 8px;
				position: relative;
				align-items: center;
				display: flex;
				justify-content: center;

				&:before, &:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 100%;
					border: 5px solid transparent;
					border-top-color: #ffffff;
				}

				&:before {
					z-index: 100;
					animation: spin 1s infinite;
				}

				&:after {
					border: 5px solid rgba(0, 0, 0, 0.25);
				}

				@keyframes spin{
					0%{ transform: rotate(0deg); }
					100%{ transform: rotate(360deg); }
				}
			}



		}

		.message {
			position: relative;
			margin-top: 20px;
			color: #4e502c;
			transition: opacity 1s;
		}
	}
}
