@import "shared/themes/fonts";

.history {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	row-gap: 24px;

	> .step {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		row-gap: 10px;

		> .info {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			column-gap: 20px;

			> .avatar {
				position: relative;
				$size: 56px;
				height: $size;
				width: $size;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: rgba(192, 235, 202, 1);
				box-shadow: inset -1px -1px 1px rgba(22, 29, 64, 0.15), inset 1px 1px 1px rgba(155, 163, 211, 0.1);
				overflow: hidden;
				cursor: pointer;

				> span {
					font-weight: 900;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			> .stepInfo {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: stretch;
				@include font('Inter', 12px, 500, 15px);

				> .name {
					color: rgba(192, 235, 202, 1);
				}

				> .action {
					color: rgba(116, 140, 124, 1);
				}
			}
		}

		> .cards {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: stretch;
			column-gap: 20px;

			%cards-row {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: stretch;
				column-gap: 5px;
			}

			.card {
				$height: 48;
				$width: 34;
				max-height: #{$height}px;
				max-width: #{$width}px;
				width: 100%;
				aspect-ratio: $height / $width;
			}

			> .userCards {
				@extend %cards-row;
			}

			> .tableCards {
				@extend %cards-row;
			}
		}
	}
}