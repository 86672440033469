.ws_status {
	$font-color: #9ba3a5;
	$wsH: 25px;
	height: $wsH;
	display: flex;
	position: fixed;
	bottom: 10px;
	right: 20px;
	padding: 5px;
	border-radius: 6px;
	background: rgba(0,0,0,.3);
	font-size: 0.797em;
	z-index: 999;
	&.active {
		border-radius: 0 0 6px 6px;
	}
	.status {
		color: #6a2a2a;
		&:hover {
			cursor: pointer;
		}
		&.connected {
			color: #3b6a2a;
		}
	}
	.menu {
		position: absolute;
		width: 220px;
		bottom: $wsH;
		right: 0;
		background: rgba(0,0,0,.3);
		border-radius: 6px 6px 0 6px;
		padding: 6px;
		.address {
			color: $font-color;
			background: rgba(0,0,0,.3);
			padding: 6px;
			margin-bottom: 6px;
		}
		.buttons {
			gap: 6px;
			display: flex;
			justify-content: space-between;
			.group {
				gap: 6px;
				display: inline-grid;
			}
			.disabled {
				opacity: .5;
				pointer-events: none;
				cursor: default;
			}
			p {
				width: 100%;
				background: rgba(0,0,0,.3);
				color: $font-color;
				padding: 6px;
				border-radius: 6px;
				font-size: 16px;
				cursor: pointer;
			}
		}
	}
}
