.progress {
	> circle {
		width:100%;
		height:100%;
		fill:none;
		stroke: transparent;
		stroke-linecap: round;
		stroke-linejoin: round;

		&.bar {
			stroke: transparent;
		}
		&.overlay {
			stroke: rgba(153, 233, 100, 1);
			transition: stroke-dashoffset 0.1s linear;
		}
	}
}