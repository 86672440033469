@import "shared/themes/fonts";

.room {
	color: rgba(192, 235, 202, 1);
	@include font('Inter', 10px, 500, 12px);
	letter-spacing: 0.02em;
	visibility: hidden;

	&[data-show="true"] {
		visibility: visible;
	}
}

.blinds {
	color: rgba(192, 235, 202, 1);
	@include font('Inter', 10px, 500, 12px);
	letter-spacing: 0.02em;

	> .values {
		font-weight: 700;
	}
}

.gameSpeed {
	color: rgba(192, 235, 202, 1);
	@include font('Inter', 10px, 500, 12px);
	letter-spacing: 0.02em;
}

.requiredPlayers {
	color: rgba(192, 235, 202, 1);
	@include font('Inter', 10px, 500, 12px);
	letter-spacing: 0.02em;
}

.cards {
	$card-width: 27.4px;
	$column-gap: 2.75px;
	$row-gap: 2.82px;
	position: relative;
	align-self: center;
	column-gap: $column-gap !important;
	row-gap: $row-gap !important;
	max-width: 3 * $card-width + 2 * $column-gap;

	.list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: flex-start;
		column-gap: $column-gap;
		row-gap: $row-gap;
		z-index: 1;
	}

	.card {
		width: $card-width;
		height: 37.5px;
		min-height: unset;
		min-width: unset;
		aspect-ratio: 27 / 37;
		padding: 4.5px;
		transition: var(--transition);

		.rank {
			@include font('Gilroy', 14px, 600, 82%);
		}

		.suit {
			--suit-size: 10px;
		}

		&.placeholder {
			z-index: -1;
		}

		&.active {
			box-shadow: 0 0 3px yellow;
			transform: translateY(-10%);
		}

		&.inactive {
			opacity: 0.5;
			filter: brightness(0.3);
		}
	}
}