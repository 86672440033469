@import "shared/themes/fonts";

%cardBack {
	$height: 20;
	$width: 15;
	min-height: unset;
	min-width: unset;
	height: #{$height}px;
	width: #{$width}px;
	aspect-ratio: calc($height / $width);
}

.place {
	--icon-size: 40px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 5px;

	%text {
		@include font('Inter', 12px, 500, 15px);
	}

	.person {
		$icon-size: var(--icon-size, 40px);
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.icon {
			$size: $icon-size;
			position: relative;
			height: $size;
			width: $size;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: rgba(53, 62, 64, 1);
			border-radius: 50%;
			color: rgb(172, 172, 172);
			overflow: hidden;
			filter: drop-shadow(10.8135px 10.8135px 21.627px rgba(0, 0, 0, 0.25)) brightness(1);

			&.text {
				box-shadow: inset -0.814798px -0.814798px 0.814798px rgba(22, 29, 64, 0.15), inset 0.814798px 0.814798px 0.814798px rgba(155, 163, 211, 0.1);
			}

			> span {
				font-weight: 900;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: top;
			}

			&.highlight {
				$offset-size: 3px;
				$size: calc($icon-size + $offset-size);
				$shadow-size: calc($size * 1.5);
				$half-shadow-size: calc(0.5 * $shadow-size);
				box-shadow: 0 0 0 $offset-size #99E964, 0 0 $size 0 #99E964, 0 0 $half-shadow-size 0 #99E964, 0 0 $shadow-size 0 #99E964;
			}
		}

		.progressContainer {
			$size: calc(#{$icon-size} + 10px);
			position: absolute;
			top: 50%;
			left: 50%;
			height: $size;
			width: $size;
			transform: translateX(-50%) translateY(-50%);
			opacity: 0;
			transition: var(--transition);

			&.show {
				opacity: 1;
			}

			.progress {
				height: 100%;
				width: 100%;
			}
		}

		.bet {
			position: absolute;
		}

		&[data-side] {
			&[data-side="0"] {
				.bet {
					top: 50%;
					left: calc(100% + 9px);
				}
			}

			&[data-side="1"] {
				.bet {
					top: calc(100% + 30px + 9px);
					left: 100%;
				}
			}

			&[data-side="2"] {
				.bet {
					top: 50%;
					right: calc(100% + 9px);
				}
			}

			&[data-side="3"] {
				.bet {
					bottom: calc(100% + 9px);
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	.action {
		position: absolute;
		bottom: -2px;
	}

	.dealerMark {
		$size: 18px;
		position: absolute;
		top: 0;
		left: 0;
		height: $size;
		width: $size;
		transform: translateX(-50%) translateY(-50%);
		z-index: 1;
		opacity: 0;
		transition: var(--transition);

		&.show {
			opacity: 1;
		}
	}

	.cards {
		position: absolute;
		display: flex;
		flex-direction: row;
		pointer-events: none;

		&.hidden {
			bottom: 0;
			right: 0;

			.card {
				@extend %cardBack;
				margin-left: -10px;
				filter: drop-shadow(-0.814798px 0.814798px 0.814798px rgba(0, 0, 0, 0.15));
			}

			&.active {
				@keyframes shake {
					to {
						transform: rotate(15deg);
					}
				}

				.card {
					&:last-child {
						transform-origin: 30% 100%;
						animation: 1s shake ease-in-out infinite alternate;
					}
				}
			}
		}

		&.opened {
			$suit: 12px;
			bottom: 50%;
			right: 0;
			transform: translateX(0) translateY(50%);
			transition: var(--transition);

			.card {
				$min-size: 25px;
				min-height: $min-size;
				min-width: $min-size;
				gap: 5px;
				padding: 4px;
				margin-left: -7px;
				box-shadow: -0.8px 0.8px 0.8px rgba(0, 0, 0, 0.25);
				filter: brightness(1);
				transition: var(--transition);

				.rank {
					@include font('Gilroy', 11.6383px, 600, 82%);
				}

				.suit {
					$size: $suit;
					--suit-size: $size;
					height: $size;
					width: $size;
				}
			}

			&.withAction {
				bottom: calc(50% + 0.5 * $suit);
			}

			&.highlighted {
				.card {
					&.active {
						box-shadow: 0 0 3px yellow;
						transform: translateY(-10%);
					}

					&.inactive {
						filter: brightness(0.3);
					}
				}
			}
		}
	}

	.info {
		position: absolute;
		top: calc(100% + 8px);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		row-gap: 5px;

		.name {
			@extend %text;
			font-weight: 500;
			color: rgba(192, 235, 202, 1);
			text-align: center;
			white-space: nowrap;
		}

		.stack {
			@extend %text;
			font-weight: 700;
			color: rgba(235, 235, 235, 1);
			white-space: nowrap;
		}
	}

	&.inactive {
		.person .icon {
			filter: drop-shadow(10.8135px 10.8135px 21.627px rgba(0, 0, 0, 0.25)) brightness(0.3);
		}
	}

	&:last-child {
		.person {
			&[data-side] {
				&[data-side="1"] {
					.bet {
						left: unset;
						right: 100%;
					}
				}
			}
		}
	}
}

.animatedChips {
	pointer-events: none;
	user-select: none;

	> .chipWrapper {
		position: fixed;
		opacity: 0;
	}
}

.animatedCards {
	pointer-events: none;
	user-select: none;

	> .cardWrapper {
		position: fixed;
		opacity: 0;

		> .card {
			@extend %cardBack;
		}
	}
}